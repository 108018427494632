<!--
  - Copyright (C) 2024. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
   <b-navbar toggleable="sm" type="light" variant="light">
     <b-navbar-nav class="mr-auto">
        <slot name="extras"></slot>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <slot name="items"></slot>
      </b-navbar-nav>
    </b-navbar>
</template>

<script>
  export default {
    name: 'navbar-secondary'
  }
</script>

<style lang="scss" scoped>

</style>
